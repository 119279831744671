import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const Donate = () => {
  return (
    <section className="donate pd">
      <h2 className="donate_title">Donate</h2>
      <a
        href="https://www.patreon.com/kanaytakum"
        target="_blank"
        rel="noreferrer"
        className="buttonWhite fab donate_patreon"
      >
        Patreon <ArrowForwardIosIcon />
      </a>
    </section>
  );
};
export default Donate;
