const BeatDataset = {
  hero: [
    {
      title: "2023 Playlist",
      youtubeURL: "videoseries?list=PLzppDWMHLHALLDNt7cC-82kacUMopmhJh&"
    },
    {
      title: "2022 Playlist",
      youtubeURL: "videoseries?list=PLzppDWMHLHAIIyY0BH1HLdPc3YKBKSane&"
    },
    {
      title: "2021 Playlist",
      youtubeURL: "videoseries?list=PLzppDWMHLHAJZnp588Ke3EYPF83N7m5-y&"
    },
    {
      title: "2020 Playlist",
      youtubeURL: "videoseries?list=PLzppDWMHLHAKsZp80gyrVeRYpGLgApGrW&"
    }
  ],
  lists: {
    y2023: {
      "bokyaku": {
        title: "忘却: Bokyaku",
        youtubeURL: "ddart43PmXA?",
        bpm: "89",
        key: "",
        tag: ["89", "Lo-Fi"],
        instruments: ["Koto", "Synthesizer"]
      },
      "rakka-hoko": {
        title: "落下歩行: Rakka-Hoko",
        youtubeURL: "zVKHbUBH5ho?",
        bpm: "101",
        key: "C, Am",
        tag: ["101", "Lo-Fi"],
        instruments: ["Synthesizer"]
      },
      "kiri-no-naka": {
        title: "霧の中: Kiri-No-Naka",
        youtubeURL: "B5aTpc4Dp08?",
        bpm: "97",
        key: "",
        tag: ["97", "Lo-Fi"],
        instruments: ["Piano", "Synthesizer"]
      },
    },
    y2022: {
      "imada-kurikaesu": {
        title: "未だ 繰り返す: Imada-kurikaesu",
        youtubeURL: "",
        bpm: "122",
        key: "",
        tag: ["Lo-Fi", "122"],
        instruments: ["Syamisen", "Syakuhachi"]
      },
      "konohi": {
        title: "この日: Konohi",
        youtubeURL: "cKmoIk8mDHs?",
        bpm: "118",
        key: "",
        tag: ["Lo-Fi", "118"],
        instruments: ["Electric-Guitar", "Synthesizer", "Piano"]
      },
      "kugi-hai": {
        title: "釘・灰: Kugi-hai",
        youtubeURL: "JXG-U81Q4KQ?",
        bpm: "127",
        key: "",
        tag: ["Trip", "127"],
        instruments: ["Fiddle", "Piano-Sampling"]
      },
      "kyoei": {
        title: "虚栄: Kyoei",
        youtubeURL: "",
        bpm: "132",
        key: "",
        tag: ["Lo-Fi", "132"],
        instruments: ["Synthesizer", "Syamisen"]
      },
      "tada-omou": {
        title: "唯想う: Tada-omou",
        youtubeURL: "i_4TaM627Zk?",
        bpm: "98",
        key: "",
        tag: ["Lo-Fi", "98"],
        instruments: ["Sutra", "Koto", "Organ"]
      },
      "ugokenai-ten": {
        title: "動けない点: Ugokenai-ten",
        youtubeURL: "zsuCXL-fNpA?",
        bpm: "113",
        key: "",
        tag: ["Lo-Fi", "113"],
        instruments: ["Piano"]
      }
    },
    y2021: {
      "koku": {
        title: "虚空: Koku",
        youtubeURL: "M6p3Gi2h5B0?",
        bpm: "83",
        key: "",
        tag: ["Lo-Fi", "83"],
        instruments: ["Electric-Guitar", "Synthesizer", "Fiddle"]
      },
      "bobaku": {
        title: "茫漠: Bobaku",
        youtubeURL: "PqqFMZAl_3U?",
        bpm: "79",
        key: "",
        tag: ["Lo-Fi", "79"],
        instruments: ["Piano", "Synthesizer", "Vintage-Keyboard"]
      },
      "kyokan": {
        title: "叫喚: Kyokan",
        youtubeURL: "k1NHJwokLGE?",
        bpm: "83",
        key: "",
        tag: ["Lo-Fi", "79"],
        instruments: ["Electric-Guitar", "Guiter-Sampling"]
      },
      "zankyo": {
        title: "残響: Zankyo",
        youtubeURL: "dH6HrL2923c?",
        bpm: "83",
        key: "",
        tag: ["Lo-Fi", "83"],
        instruments: ["Electric-Guitar", "Guiter-Sampling"]
      },
      "kyosyu": {
        title: "郷愁: Kyosyu",
        youtubeURL: "7Jv6q4kgkvw?",
        bpm: "79",
        key: "",
        tag: ["Cross-Rhythm", "79"],
        instruments: ["Fiddle", "Fiddle-Sampling", "Synthesizer"]
      },
      "kyomei": {
        title: "共鳴: Kyomei",
        youtubeURL: "O1MH95K44mA?",
        bpm: "79",
        key: "",
        tag: ["79"],
        instruments: ["Piano", "Synthesizer", "Syakuhachi"]
      },
      "kowaku": {
        title: "蠱惑: Kowaku",
        youtubeURL: "8BLr4MN6q_4?",
        bpm: "73",
        key: "",
        tag: ["73"],
        instruments: ["Electric-Guitar"]
      },
      "paragram": {
        title: "Paragram",
        youtubeURL: "tzUsOrfMC6c?",
        bpm: "91",
        key: "",
        tag: ["91"],
        instruments: ["Piano", "Piano-Sampling"]
      },
      "yuen": {
        title: "悠遠: Yuen",
        youtubeURL: "SAqqZctiwSk?",
        bpm: "71",
        key: "",
        tag: ["Chill", "Lo-Fi", "71"],
        instruments: ["Electric-Guitar", "Bass-Sampling"]
      },
      "toho": {
        title: "途方: Toho",
        youtubeURL: "CGQdkuSWr0g?",
        bpm: "121",
        key: "",
        tag: ["Chill", "Lo-Fi", "121"],
        instruments: [
          "Electric-Guitar",
          "Vintage-Keyboard",
          "Synthesizer",
          "Pizzicato-Bass"
        ]
      },
      "eisya": {
        title: "映写: Eisya",
        youtubeURL: "0fmBMpXooYU?",
        bpm: "139",
        key: "",
        tag: ["Chill", "Lo-Fi", "Drunk", "139"],
        instruments: ["Electric-Guitar"]
      },
      "kakera": {
        title: "欠片: Kakera",
        youtubeURL: "yv2HcfiDqnM?",
        bpm: "113",
        key: "",
        tag: ["113"],
        instruments: ["Synthesizer", "Saxophone-Sampling"]
      },
      "Kieteiku": {
        title: "消えていく: Kieteiku",
        youtubeURL: "4YkFVRVW3ek?",
        bpm: "97",
        key: "",
        tag: ["Lo-Fi", "97"],
        instruments: ["Piano"]
      },
      "Kage-Sakasama": {
        title: "影逆さま: Kage-Sakasama",
        youtubeURL: "qllLloOiq1g?",
        bpm: "122",
        key: "",
        tag: ["Lo-Fi", "122"],
        instruments: [
          "Synthesizer",
          "Saxophone",
          "Vintage-Keyboard",
          "Wobble-Bass"
        ]
      },
      "Sen-Nuritubusu": {
        title: "線塗りつぶす: Sen-Nuritubusu",
        youtubeURL: "xinsYFxSswY?",
        bpm: "122",
        key: "",
        tag: ["Lo-Fi", "Chill", "122"],
        instruments: ["Synthesizer", "Vintage-Keyboard"]
      },
      "Sakuran": {
        title: "錯乱: Sakuran",
        youtubeURL: "aGVmBeMQyq4?",
        bpm: "79",
        key: "",
        tag: ["Lo-Fi", "79"],
        instruments: ["Piano", "Shamisen", "Synthesizer", "Voice"]
      },
      "Kondaku": {
        title: "混濁: Kondaku",
        youtubeURL: "pfE7vU9fQfY?",
        bpm: "71",
        key: "",
        tag: ["Japanese", "71"],
        instruments: ["Koto", "Kabuki-Voice", "Synthesizer"]
      }
    },
    y2020: {
      "ha": {
        title: "葉: Ha",
        youtubeURL: "3hPZyyKnfWs?",
        bpm: "87",
        key: "A#m",
        tag: ["Japanese", "87"],
        instruments: ["Koto"]
      },
      "namari": {
        title: "鉛: Namari",
        youtubeURL: "qlTlH8Efepw?",
        bpm: "89",
        key: "",
        tag: ["Japanese", "89"],
        instruments: ["Koto"]
      },
      "yuki": {
        title: "雪: Yuki",
        youtubeURL: "HB8UbsITxeI?",
        bpm: "97",
        key: "",
        tag: ["Japanese", "97"],
        instruments: ["Koto"]
      },
      "soku": {
        title: "塞: Soku",
        youtubeURL: "JOAVcUNNakk?",
        bpm: "81",
        key: "A#m",
        tag: ["Japanese", "81"],
        instruments: ["Koto"]
      },
      "nagi": {
        title: "凪: Nagi",
        youtubeURL: "xYqiY-Od0DY?",
        bpm: "87",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "87"],
        instruments: []
      },
      "myou": {
        title: "妙: Myou",
        youtubeURL: "i0oTGjkGZfw?",
        bpm: "91",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "91"],
        instruments: ["Piano", "Fiddle"]
      },
      "you": {
        title: "妖: You",
        youtubeURL: "gWJGjBlwykU?",
        bpm: "85",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "85"],
        instruments: ["Piano", "Syakuhachi"]
      },
      "haruka": {
        title: "遥: Haruka",
        youtubeURL: "p849yBOkilA?",
        bpm: "87",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "87"],
        instruments: ["Piano", "Fiddle"]
      },
      "tomoe": {
        title: "巴: Tomoe",
        youtubeURL: "hQgtc2wU6T8?",
        bpm: "103",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "Trip", "103"],
        instruments: ["Syamisen", "Fiddle"]
      },
      "torico": {
        title: "虜: Torico",
        youtubeURL: "MdZW5qaAlAA?",
        bpm: "103",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "Trip", "103"],
        instruments: ["Syamisen", "Fiddle"]
      },
      "I": {
        title: "畏: I",
        youtubeURL: "ghKZ-x7PQOg?",
        bpm: "103",
        key: "A#m",
        tag: ["Lo-Fi", "Electronica", "103"],
        instruments: ["Syakuhachi", "Synthesizer"]
      },
      "akane": {
        title: "茜: Akane",
        youtubeURL: "XrzyVq6Nhok?",
        bpm: "127",
        key: "A#m",
        tag: ["Japanese", "Lo-Fi", "127"],
        instruments: ["Syamisen", "Fiddle"]
      }
    }
  }
}

export default BeatDataset;