import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { PlayListItem, Title } from './modules/';
import InitPlayList from '..';
import DownloadIcon from '@mui/icons-material/Download';

const Home = (props: any) => {
  const initPlayList = useContext(InitPlayList);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(props.beats.lists[initPlayList.name]);
  }, [data, props.beats.lists, initPlayList.name]);

  return (
    <>
      <Title title="Home" />
      <section id="hero" className="pd faw">
        <div id="hero_box">
          <h1 id="hero_box_title">Lo-Fi Tracks</h1>
          <h2 id="hero_box_subTitle">
            {props.beats.hero[0].title}
            <YouTubeIcon
              id="hero_box_subTitle_icon"
              onClick={() => {
                props.setYoutube(props.beats.hero[0].youtubeURL);
              }}
            />
          </h2>
        </div>
      </section>
      <section className="homePlayList section pd">
        <h2 className="homePlayList_title">{initPlayList.year} Beat list</h2>
        <div className="homePlayList_box">
          {Object.entries(data).map((value: any, index: number) => {
            return (
              <PlayListItem
                value={value}
                index={index}
                y={initPlayList.name}
                setYoutube={props.setYoutube}
                key={index.toString()}
              />
            );
          })}
        </div>
        <Link to={'/beatlist'} className="homePlayList_allbeat button fab">
          <span>Brat list</span>
          <DownloadIcon></DownloadIcon>
        </Link>
      </section>
    </>
  );
};

export default Home;
