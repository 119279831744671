import { useState, useEffect } from 'react';

const D = (props) => {
  const [lists, setLists] = useState(props.beats.lists);
  const [list23, setList23] = useState(props.beats.lists.y2023);
  const [list22, setList22] = useState(props.beats.lists.y2022);
  const [list21, setList21] = useState(props.beats.lists.y2021);
  const [list20, setList20] = useState(props.beats.lists.y2020);
  useEffect(() => {
    setLists(props.beats.hero);
    setList23(props.beats.lists.y2023);
    setList22(props.beats.lists.y2022);
    setList21(props.beats.lists.y2021);
    setList20(props.beats.lists.y2020);
  }, []);

  const getList = (a) => {
    return (
      <div>
        {Object.entries(a).map(([key, value]) => {
          return (
            <>
              <p key={key}>
                {key},{value.title},{value.youtubeURL},{value.bpm},{value.key}
              </p>
              <span>{'['}</span>
              {value.tag.map((v) => {
                return <span>{v},</span>;
              })}
              <span>{'],'}</span>
              
              <span>{'['}</span>
              {value.tag.map((v) => {
                return <span>{v},</span>;
              })}
              <span>{'],'}</span>
            </>
          );
        })}

        {Object.entries(a).map(([key, value]) => {
          return value.instruments.map((v) => {
            return <span>{v},</span>;
          });
        })}
      </div>
    );
  };

  const getHero = (a) => {
    return (
      <div>
        {Object.entries(a).map(([key, value]) => {
          return (
            <p key={key}>
              {value.name},{value.title},{value.youtubeURL}
            </p>
          );
        })}
      </div>
    );
  };

  return (
    <section className="section">
      <h2>Hero</h2>
      {getHero(lists)}
      <h2>2023</h2>
      {getList(list23)}
      <h2>2022</h2>
      {getList(list22)}
      <h2>2021</h2>
      {getList(list21)}
      <h2>2020</h2>
      {getList(list20)}
    </section>
  );
};

export default D;
