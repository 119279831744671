import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InitPlayList from '../..';
import icon from '../../assets/images/icon@0.75x.png';
import { useLocation } from 'react-router-dom';
const Header = () => {
  const initPlayList = useContext(InitPlayList);
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState('');

  useEffect(() => {
    if (location.pathname === '/') {
      setHeaderClass('');
    } else {
      setHeaderClass('header_background');
    }
  }, [location.pathname]);

  return (
    <header className={`header fa aligncenter pd ${headerClass}`}>
      <nav className="header_nav flex aligncenter">
        <h1 className="header_nav_logo">
          <Link className="header_nav_logo_link fa" to={'/'}>
            <img src={icon} alt="" className="header_nav_logo_icon" />
            kanaytakum.com
          </Link>
        </h1>

        <Link
          className="header_nav_menu fa"
          to={`/beatlist/${initPlayList.name}`}
        >
          Beat List
        </Link>
      </nav>
    </header>
  );
};

export default Header;
