const Instruments = [
  'Electric-Guitar',
  'Synthesizer',
  'Fiddle',
  'Piano',
  'Vintage-Keyboard',
  'Guiter-Sampling',
  'Pizzicato-Bass',
  'Bass-Sampling',
  'Saxophone-Sampling',
  'Saxophone',
  'Wobble-Bass',
  'Shamisen',
  'Voice',
  'Koto',
  'Kabuki-Voice',
  'Syakuhachi',
  'Sutra',
  'Organ'
]

export default Instruments;