import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ToTop, Side } from './modules';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DownloadIcon from '@mui/icons-material/Download';
import { Helmet } from 'react-helmet-async';

const Beat = (props) => {
  let y = useParams().y;
  let id = useParams().id;
  const [data, setData] = useState({});
  const [dataTag, setDataTag] = useState([]);
  const [dataInstruments, setDataInstruments] = useState([]);

  useEffect(() => {
    if (props.beats.lists[y][id]) {
      setData(props.beats.lists[y][id]);
      setDataTag(props.beats.lists[y][id].tag);
      setDataInstruments(props.beats.lists[y][id].instruments);
    } else {
      setData(props.beats.lists.y2022.konohi);
      setDataTag(props.beats.lists.y2022.konohi.tag);
      setDataInstruments(props.beats.lists.y2022.konohi.instruments);
    }
    console.log(data.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <ToTop />
      <section className="section">
        <div className="beat fbw">
          <div className="beat_main section_box_contents pd">
            {data.bpm && <span className="beat_main_bpm">BPM: {data.bpm}</span>}
            {data.key && <span className="beat_main_key">KEY: {data.key}</span>}
            <div className="fa">
              {data.youtubeURL && (
                <YouTubeIcon
                  onClick={() => {
                    props.setYoutube(data.youtubeURL);
                  }}
                  className="beat_main_youtube"
                ></YouTubeIcon>
              )}
              {data.title && <h2 className="beat_main_title">{data.title}</h2>}
            </div>

            <a
              href={require(`../assets/sounds/mp3/mastering/${y}/${id}.mp3`)}
              className="beat_main_download button fab"
            >
              <span>Mastering Ver</span>
              <DownloadIcon />
            </a>

            <a
              href={require(`../assets/sounds/mp3/normal/${y}/${id}.mp3`)}
              className="beat_main_download button fab"
            >
              <span>Normal Ver</span>
              <DownloadIcon />
            </a>

            <div className="beat_main_instruments">
              <h3>Instruments</h3>
              {dataInstruments.map((value, index) => {
                return (
                  <Link
                    to={`/search/${value}`}
                    key={index.toString()}
                    className="buttonSmall"
                  >
                    {value}
                  </Link>
                );
              })}
            </div>

            <div className="beat_main_tag">
              <h3>Tag</h3>
              {dataTag.map((value, index) => {
                return (
                  <Link
                    to={`/search/${value}`}
                    key={index.toString()}
                    className="buttonSmall"
                  >
                    {value}
                  </Link>
                );
              })}
            </div>
          </div>
          <Side />
        </div>
      </section>
    </>
  );
};

export default Beat;
