import { Helmet } from 'react-helmet-async';

type Props = {
  title: String;
};

const Title = (props: Props) => {
  return (
    <Helmet>
      <title>{props.title} - Free Lo-Fi kanaytakum.com</title>
    </Helmet>
  );
};

export default Title;
