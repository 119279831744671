const Bpm = [
  '139',
  '132',
  '127',
  '122',
  '121',
  '118',
  '113',
  '103',
  '101',
  '98',
  '97',
  '91',
  '89',
  '87',
  '83',
  '79',
  '73',
  '71',
]

export default Bpm;