import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import icon from '../../assets/images/icon.png';
const Footer = () => {
  return (
    <footer className="footer pd fajc">
      <h6 className="fajc footer_info">
        <img src={icon} className="footer_info_logo" alt="" />
        kanaytakum.com
      </h6>
      <a
        href="https://www.youtube.com/channel/UC4KN5LRwrFASUA4dzDCsW_w"
        className="footer_icon"
        id="footerYoutube"
        target="_blank"
        rel="noreferrer"
      >
        <YouTubeIcon />
      </a>
      <a
        href="https://twitter.com/kanaytakum"
        className="footer_icon"
        id="footerTwitter"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterIcon />
      </a>
    </footer>
  );
};

export default Footer;
