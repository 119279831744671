import Instruments from '../../assets/data/Instruments';
import Tag from '../../assets/data/Tag';
import Bpm from '../../assets/data/Bpm';
import { Link } from 'react-router-dom';
const Side = () => {
  return (
    <div className="side">
      <h4 className="side_title">Instruments</h4>
      {Instruments.map((value, index) => {
        return (
          <Link
            to={`/search/${value}`}
            className="buttonSmall"
            key={index.toString()}
          >
            {value}
          </Link>
        );
      })}
      <h4 className="side_titleTag">Tag</h4>
      {Tag.map((value, index) => {
        return (
          <Link
            to={`/search/${value}`}
            className="buttonSmall"
            key={index.toString()}
          >
            {value}
          </Link>
        );
      })}
      <h4 className="side_titleTag">BPM</h4>
      {Bpm.map((value, index) => {
        return (
          <Link
            to={`/search/${value}`}
            className="buttonSmall"
            key={index.toString()}
          >
            {value}
          </Link>
        );
      })}
    </div>
  );
};

export default Side;
