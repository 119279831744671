const Error404 = () => {
  return (
    <section className="section fa" id="error">
      <div id="error_div">
        <h2 id="error_div_title">404 ERROR</h2>
      </div>
    </section>
  );
};

export default Error404;
