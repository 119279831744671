import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ToTop, Side } from './modules';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import InitPlayList from '..';
import { PlayListItem, Title } from './modules/';

const BeatList = (props: any) => {
  let y = useParams<string>().y;
  const [data, setData] = useState({});
  const [year, setYear] = useState('');
  const initPlayList = useContext(InitPlayList);
  const beatListLinks = [
    { y: 'y2020', text: '2020' },
    { y: 'y2021', text: '2021' },
    { y: 'y2022', text: '2022' },
    { y: 'y2023', text: '2023' },
  ];

  useEffect(() => {
    if (y === 'y2020' || y === 'y2021' || y === 'y2022' || y === 'y2023') {
      setData(props.beats.lists[y]);
      setYear(y);
    } else {
      setData(props.beats.lists[initPlayList.name]);
      setYear(initPlayList.name);
    }
  }, [initPlayList.name, props.beats.lists, y]);

  const beatList_link_style = (str: string) => {
    if (y === str) return 'beatList_link';
    return '';
  };

  return (
    <>
      <Title title={'Beat List ' + y?.replace('y', '')} />
      <ToTop />
      <section className="section">
        {/* <h2 className="beatList_title">Beat List</h2> */}
        <div className="section_box fbw">
          <div className="beatList_main section_box_contents pd">
            <div className="flex">
              {beatListLinks.map((value, index) => {
                return (
                  <span className="fa" key={index.toString()}>
                    <ArrowRightIcon
                      className="beatList_main_icon"
                      id={beatList_link_style(value.y!)}
                    ></ArrowRightIcon>
                    <Link
                      className="beatList_main_yearSelect aniLink"
                      to={`/beatlist/${value.y}`}
                    >
                      {value.text}
                    </Link>
                  </span>
                );
              })}
            </div>
            <div className="beatList_main_box">
              {Object.entries(data).map((value: any, index: number) => {
                return (
                  <PlayListItem
                    value={value}
                    index={index}
                    y={year}
                    setYoutube={props.setYoutube}
                    key={index.toString()}
                  />
                );
              })}
            </div>
          </div>
          <Side />
        </div>
      </section>
    </>
  );
};

export default BeatList;
