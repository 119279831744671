import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToTop, Side } from './modules';
import PlayListItem from './modules/playListItem';

const Search = (props: any) => {
  const keyword = useParams().keyword;
  const [data1, setData1] = useState({});
  const [data2, setData2] = useState({});
  const [data3, setData3] = useState({});
  const [data4, setData4] = useState({});

  useEffect(() => {
    setData1(props.beats.lists.y2020);
    setData2(props.beats.lists.y2021);
    setData3(props.beats.lists.y2022);
    setData4(props.beats.lists.y2023);
  }, []);

  return (
    <section className="section">
      <ToTop />
      <div className="section_box fbw">
        <div className="search section_box_contents pd">
          <h2>{keyword}</h2>
          {Object.entries(data1).map((value: any, index) => {
            let a = value[1].instruments;
            let b = value[1].tag;
            if (a.includes(keyword) || b.includes(keyword)) {
              return (
                <PlayListItem
                  value={value}
                  index={index}
                  y={'y2020'}
                  setYoutube={props.setYoutube}
                  key={index.toString()}
                />
              );
            }
            return null;
          })}
          {Object.entries(data2).map((value: any, index) => {
            let a = value[1].instruments;
            let b = value[1].tag;
            if (a.includes(keyword) || b.includes(keyword)) {
              return (
                <PlayListItem
                  value={value}
                  index={index}
                  y={'y2021'}
                  setYoutube={props.setYoutube}
                  key={index.toString()}
                />
              );
            }
            return null;
          })}
          {Object.entries(data3).map((value: any, index) => {
            let a = value[1].instruments;
            let b = value[1].tag;
            if (a.includes(keyword) || b.includes(keyword)) {
              return (
                <PlayListItem
                  value={value}
                  index={index}
                  y={'y2022'}
                  setYoutube={props.setYoutube}
                  key={index.toString()}
                />
              );
            }
            return null;
          })}
          {Object.entries(data4).map((value: any, index) => {
            let a = value[1].instruments;
            let b = value[1].tag;
            if (a.includes(keyword) || b.includes(keyword)) {
              return (
                <PlayListItem
                  value={value}
                  index={index}
                  y={'y2023'}
                  setYoutube={props.setYoutube}
                  key={index.toString()}
                />
              );
            }
            return null;
          })}
        </div>
        <Side />
      </div>
    </section>
  );
};

export default Search;
