import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

// *
const initPlayList = {
  year: '2023',
  name: 'y2023',
};

const InitPlayList = createContext(initPlayList);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <InitPlayList.Provider value={initPlayList}>
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>
  </InitPlayList.Provider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();

export default InitPlayList;
