import CloseIcon from '@mui/icons-material/Close';
type Props = {
  youtube: string;
  setYoutube: React.Dispatch<React.SetStateAction<string>>;
};
const Youtube = (props: Props) => {
  return (
    <section id="YoutubePlayer">
      <button
        onClick={() => {
          props.setYoutube('');
        }}
        id="YoutubePlayer_close"
      >
        <CloseIcon />
      </button>
      <iframe
        src={'https://www.youtube.com/embed/' + props.youtube + 'autoplay=1'}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        id="YoutubePlayer_iframe"
        title="youtube"
        allowFullScreen
      ></iframe>
    </section>
  );
};

export default Youtube;
