import YouTubeIcon from '@mui/icons-material/YouTube';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Link } from 'react-router-dom';

const PlayList = (props: any) => {
  return (
    <div className="playListItem fab" key={props.index}>
      <div className="qfa">
        <span className="playListItem_bpm">BPM: {props.value[1].bpm}</span>
        <p className="playListItem_title fa">
          <ArrowRightIcon />
          <Link
            className="playListItem_title_link aniLink"
            to={`/beat/${props.y}/${props.value[0]}`}
          >
            {props.value[1].title}
          </Link>
        </p>
      </div>
      <div className="fa">
        {props.value[1].youtubeURL && (
          <YouTubeIcon
            className="playListItem_youtube"
            onClick={() => {
              props.setYoutube('');
              props.setYoutube(props.value[1].youtubeURL);
            }}
          />
        )}
        <a
          href={require(`../../assets/sounds/mp3/mastering/${props.y}/${props.value[0]}.mp3`)}
          className="playListItem_download"
        >
          <DownloadIcon />
        </a>
      </div>
    </div>
  );
};

export default PlayList;
