import { useEffect, useState, useRef } from 'react';
import { Home, BeatList, Beat, Search, Error404, D } from './components/';
import { Header, Footer, Youtube, Donate } from './components/modules';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Beats from './assets/data/Beats';
import './assets/css/style.css';
import movie from './assets/movies/m.mp4';
const App = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    videoRef.current?.play();
  }, []);

  const [youtube, setYoutube] = useState('');

  return (
    <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route
          path={`/`}
          element={<Home beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route
          path={`/beatlist/`}
          element={<BeatList beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route
          path={`/beatlist/:y`}
          element={<BeatList beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route
          path={`/beat/:y/:id`}
          element={<Beat beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route
          path={`/beat/:y`}
          element={<Beat beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route
          path={`/beat/`}
          element={<Beat beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route
          path={`/search/:keyword`}
          element={<Search beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route path={`/search/`} element={<Search beats={Beats} />}></Route>
        <Route
          path={`/d/`}
          element={<D beats={Beats} setYoutube={setYoutube} />}
        ></Route>
        <Route path={`*`} element={<Error404 />}></Route>
      </Routes>
      {youtube && <Youtube youtube={youtube} setYoutube={setYoutube} />}
      <video playsInline autoPlay muted loop ref={videoRef} id="movie">
        <source src={movie} type="video/mp4" />
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
      <Donate />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
